import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {Canvas, extend, useThree, useFrame, useLoader} from 'react-three-fiber';
import { useSpring, a } from 'react-spring/three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import logo from "../../static/logoOptimized.glb";

const StyledDiv = styled.div`
  height: 100vh;
  transition: .3s ease;
`;

extend({ OrbitControls });

function Model() {
    const gltf = useLoader(GLTFLoader, logo);

    // useFrame(() => {
    //     gltf.scene.position.x += ( gltf.scene.position.x - center.x );
    //     gltf.scene.position.y += ( gltf.scene.position.y - center.y );
    //     gltf.scene.position.z += ( gltf.scene.position.z - center.z );
    // });
    return <primitive
        object={gltf.scene}
    />
}

const Controls = () => {

    const orbitRef = useRef();
    const { camera, gl } = useThree();

    useFrame(() => {
        orbitRef.current.update();
    })

    return (
        <orbitControls
            maxPolarAngle={Math.PI / 3}
            minPolarAngle={Math.PI / 3}
            autoRotate={true}
            autoRotateSpeed={10}
            ref={orbitRef}
            args={[camera, gl.domElement]}
        />
    )
};


const Plane = () => {

    const planeRef = useRef();

    useFrame(() => {
        // planeRef.current.update();
    })

    return (
        <mesh ref={planeRef}
            rotation={[-Math.PI / 2, 0, 0]}>
            <planeBufferGeometry attach="geometry" args={[100, 100]} />
            <meshPhysicalMaterial
                attach="material"
                color="red"
            />
        </mesh>
    );
}


const Box = () => {
    const [hovered, setHovered] = useState(false);
    const [active, setActive] = useState(false);
    const props = useSpring({
        scale: active ? [1.5, 1.5, 1.5] : [1, 1, 1],
        color: hovered ? 'gray' : 'greenyellow'
    });

    return (
        <a.mesh
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
            onClick={() => setActive(!active)}
            scale={props.scale}
        >
            <ambientLight />
            <spotLight position={[0, 5, 10]} penumbra={1} />
            <boxBufferGeometry attach="geometry" args={[1,1,1]} />
            <a.meshPhysicalMaterial
                attach="material"
                color={props.color}
            />
        </a.mesh>
    )
};

export default () => {
    return (
        <StyledDiv>
            <Canvas  >
                <Controls />
                <Box />
                <Plane />
                <Model />
            </Canvas>
                {/*<Contact />*/}
        </StyledDiv>
    )
};
